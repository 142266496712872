import React from 'react';
import classes from '../../components/Result/Result.module.scss';
const PageNotFound = () => {
  return(
    <div className={classes.PageContent}>
      <div className={classes.WrapContent}>
        <h1 className={classes.Title}>404</h1>
        <img className={classes.Image} src="/404_image.svg" alt="404" />
        <p><strong>Oops, the page you requested could not be found.</strong></p>
      </div>
      <div className={classes.UIWrapper}>
        <div className={classes.Buttons}>
          <div className={classes.ButtonMiddleSpacer}>
            {/* <a href="http://talktome.games" style={{ "text-decoration": "none" }}>
              <button className={classes.FirstButton}>TAKE ME HOME</button>
            </a> */}
            <a href="/" style={{ "text-decoration": "none" }}>
              <button>ZUM SPIEL</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound;


// import Link from "next/link";
// import Image from "next/image";
// import styles from "../styles/404.module.css";

// export default function FourOhFour() {
//   return (
//     <div className={styles.container}>
//       <h1>404</h1>
//       <Image src="/404_image.svg" alt="logo" width="314px" height="170px" />
//       <p>
//       Oops, the page you requested could not be found.
//       </p>
//       <button>
//         <Link href="/">
//           <a>zum spiel</a>
//         </Link>
//       </button>
//     </div>
//   );
// }
