import './App.scss';
import React, {useState} from 'react';
import GameUI from './pages/Game/GameUI';
import Header from './components/Header/Header';
import FinalResult from './pages/FinalResult/FinalResult';
import GameOver from './pages/Game/GameOver';
import {CurrentSituationContext} from './CurrentSituationContext';
import { SelectedLanguageContext } from './SelectedLanguageContext';
import { GameEndContext } from './GameEndContext';
import {GamesLengthContext} from './GamesLengthContext';
import Play from './pages/Play/Play';
import PageNotFound from './pages/PageNotFound/PageNotFound';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  const [isDeutsch, setIsDeutsch] = useState(false);
  const [currentSituation, setCurrentSituation] = useState(1);
  const [gameEnd, setGameEnd] = useState(false);
  const [gamesLength, setGamesLength] = useState(0);
  
  return (
    <div className="App">
      <SelectedLanguageContext.Provider value={[isDeutsch, setIsDeutsch]}>
      <CurrentSituationContext.Provider value={[currentSituation, setCurrentSituation]}>
        <GamesLengthContext.Provider value={[gamesLength, setGamesLength]}>
        <GameEndContext.Provider value={[gameEnd, setGameEnd]}>
        <Router>
          <Header currentSituation={currentSituation} gamesLength={gamesLength} isDeutsch={isDeutsch} />
          <div className="MainContent">
          <Switch>
            <Route exact path="/">
              <Play deutsch={false} />
            </Route>
            <Route exact path="/en">
              <Play deutsch={false} />
            </Route>
            <Route exact path="/de">
              <Play deutsch={true} />
            </Route>
            <Route path={`/game/1`}>
              <GameUI />
            </Route>
            <Route path="/game/gameover">
              <GameOver />
            </Route>
            <Route path="/finalresult">
              <FinalResult />
            </Route>
            <Route>
              <PageNotFound/>
            </Route>
          </Switch>
          </div>
        </Router>
          </GameEndContext.Provider>
        </GamesLengthContext.Provider>
      </CurrentSituationContext.Provider>
      </SelectedLanguageContext.Provider>
    </div>
  );
}

export default App;
