import { React, useEffect, useState, useContext } from "react";
import Result from "../../components/Result/Result";
import { CurrentSituationContext } from "../../CurrentSituationContext";
import { GamesLengthContext } from "../../GamesLengthContext";
import Situation from "../../components/Situation/Situation";
import GameOver from "./GameOver";
import FinalResult from "../FinalResult/FinalResult";
import LevelUp from "../LevelUp/LevelUp";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const Game = ({ loading, data, buttonLoading, buttonData }) => {
  const currentSituation = useContext(CurrentSituationContext);
  const [gamesLength, setGamesLength] = useContext(GamesLengthContext);
  const amountOfGames = data.situations.length;

  useEffect(() => {
    setGamesLength(amountOfGames);
  }, [setGamesLength, amountOfGames]);

  //sets the answer that is selected by the user, from the answerOptions array in game-data
  const [selectedAnswer, setSelectedAnswer] = useState({});

  // sets the initial selected answer so that something is rendered on the first game page.
  // is updated from the child component directly so that the correct array number
  // is received through props to define which situation is being displayed.
  useEffect(() => {
    if (loading === false) {
      const answerOptions = data.situations[0].defaultAnswerOptions;
      const answerA = answerOptions.find((a) => a.answerLetter === "A");
      setSelectedAnswer(answerA);
    }
  }, [loading, data]);

  return (
    <>
      <Router>
        {loading
          ? "loading"
          : data.situations.map((situation, i) => (
              <>
                <Switch key={"switch" + situation.id}>
                  <Route exact path={`/game/${situation.id}`}>
                    <Situation
                      id={situation.id}
                      questionOptions={situation.questionOptions}
                      defaultAnswerOptions={situation.defaultAnswerOptions}
                      alternateAnswerOptions={situation.alternateAnswerOptions}
                      thirdAnswerOptions={situation.thirdAnswerOptions}
                      currentSituation={currentSituation}
                      key={"sitch" + situation.id}
                      selectedAnswer={selectedAnswer}
                      setSelectedAnswer={(value) => setSelectedAnswer(value)}
                      loading={loading}
                      buttonLoading={buttonLoading}
                      buttonData={buttonData}
                      // image={situation.situationImage}
                    />
                  </Route>
                  <Route
                    exact
                    path={`/game/result/${situation.id}`}
                    key={"route" + situation.id}
                  >
                    <Result
                      id={situation.id}
                      answerOptions={situation.answerOptions}
                      key={"result" + situation.id}
                      selectedAnswer={selectedAnswer}
                      currentSituation={currentSituation}
                      gamesLength={gamesLength}
                      loading={loading}
                      data={data}
                      buttonLoading={buttonLoading}
                      buttonData={buttonData}
                      hasLevelUp={situation.hasLevelUp}
                      setSelectedAnswer={(value) => setSelectedAnswer(value)}
                    />
                  </Route>
                  <Route exact path={`/levelup/${situation.id}`}>
                    <LevelUp
                      levelUpImage={situation.levelUpImage}
                      id={situation.id}
                      levelUpTitle={situation.levelUpTitle}
                      levelUpText={situation.levelUpText}
                      levelUpText2={situation.levelUpText2}
                      gamesLength={amountOfGames}
                      selectedAnswer={selectedAnswer}
                      setSelectedAnswer={(value) => setSelectedAnswer(value)}
                      data={data}
                      buttonLoading={buttonLoading}
                      buttonData={buttonData}
                    />
                  </Route>
                  <Route exact path={`/game/gameover/${situation.id}`}>
                    <GameOver
                      selectedAnswer={selectedAnswer}
                      buttonLoading={buttonLoading}
                      buttonData={buttonData}
                    />
                  </Route>
                </Switch>
              </>
            ))}
        <Switch>
          <Route exact path={"/finalresult"}>
            <FinalResult
              selectedAnswer={selectedAnswer}
              buttonLoading={buttonLoading}
              buttonData={buttonData}
            />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default Game;
