import React from 'react';
import classes from './AnswerResultText.module.scss';
import DOMPurify from 'dompurify';

const AnswerResultText = (props) => {
  const spacerHeight = props.spacerHeight;
  DOMPurify.setConfig({ ADD_ATTR: ['target'] });
  return (
    <>
      <div className={classes.TextWrapper}>
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text)}} className={props.className}></p>
        <div className={classes.Spacer} style={{"height": `${spacerHeight}rem`}}></div>
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text2) }} style={{"margin": "0 0 1rem 0"}} className={props.className}></p>
      </div>
    </>
  )
}

export default AnswerResultText;