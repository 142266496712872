import {React, useContext} from 'react';
import { UserAnswersContext } from '../../UserAnswersContext';
import { GameEndContext } from '../../GameEndContext';
import { useHistory } from 'react-router-dom';
import AnswerResultTitle from '../AnswerResultTitle/AnswerResultTitle';
import AnswerResultText from '../AnswerResultText/AnswerResultText';
import ResultImage from '../ResultImage/ResultImage';
import classes from './Result.module.scss';


const Result = (props) => {
  const setGameEnd = useContext(GameEndContext)[1];
  const selectedAnswer = props.selectedAnswer;
  const gamesLength = props.gamesLength;
  const situationId = props.id;
  const hasLevelUp = props.hasLevelUp;
  const image = props.selectedAnswer.resultImage;

  // function that checks whether selectedAnswer contains isGameOver: true
  const gameOver = selectedAnswer['isGameOver'];
  const history = useHistory();
  
  let routeFunction;


  //sets selectedAnswer to next situation answer so that it displays correctly on the game page
  let setNextAnswer;
  const [userAnswers] = useContext(UserAnswersContext);
  const last = userAnswers[userAnswers.length - 1];

  if (gamesLength !== situationId && last === 'default') {
    const nextOptions = props.data.situations.find(a => a.id === props.id + 1);
    const defaultDisplay = nextOptions.defaultAnswerOptions.find(a => a.answerLetter === 'A');
    setNextAnswer = () => {
      props.setSelectedAnswer(defaultDisplay);
    }
  } else if (gamesLength !== situationId && last === 'alternate') {
    const nextOptions = props.data.situations.find(a => a.id === props.id + 1);
    const alternateDisplay = nextOptions.alternateAnswerOptions.find(a => a.answerLetter === 'A');
    setNextAnswer = () => {
      props.setSelectedAnswer(alternateDisplay);
    }
  } else if (gamesLength !== situationId && last === 'third') {
    const nextOptions = props.data.situations.find(a => a.id === props.id + 1);
    const thirdDisplay = nextOptions.thirdAnswerOptions.find(a => a.answerLetter === 'A');
    setNextAnswer = () => {
      props.setSelectedAnswer(thirdDisplay);
    }
  } else {
    setNextAnswer = () => {
      props.setSelectedAnswer(selectedAnswer);
    }
  }

  
  //this if statement handles routing
  if (gamesLength === situationId) {
    //checks if game content is finished and routes to final result page if true
     routeFunction = () => {
       setGameEnd(true);
       history.push("/finalresult");
     }
  }else if (hasLevelUp === true && selectedAnswer['isGoodAnswer'] === true) {
    //situation contains a level up case and the user selects the good answer
     routeFunction = () => {
      history.push(`/levelup/${situationId}`);
    }
  } else if (gameOver === true){
    routeFunction = () => {
      setGameEnd(true);
      history.push(`/game/gameover/${situationId}`);
    }
  } else{
     routeFunction = () => {
       setNextAnswer(last);
       history.push(`/game/${props.id + 1}`);
    }
  }
  return(
    <div className={classes.PageContent}>
      <div className={classes.WrapContent}>
        <AnswerResultTitle className={classes.Title} title={selectedAnswer.answerResultTitle}/>
        <ResultImage className={classes.Image} image={image} />
        <AnswerResultText className={classes.AnswerResultText} spacerHeight={2} text={selectedAnswer.answerResultText} text2={selectedAnswer.answerResultText2} />
      </div>
      <div className={classes.UIWrapper}>
        <div className={classes.Buttons}>
          <div className={classes.ButtonSpacerResult}>
            <button onClick={() => routeFunction()}>WEITERSPIELEN</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Result;