import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import { UserAnswersContext } from '../../UserAnswersContext';

const SayThisButton = (props) => {
  const [userAnswers, setUserAnswers] = useContext(UserAnswersContext);
  const selectedAnswer = props.selectedAnswer;
  const history = useHistory();
  const reRouteAndSetUserAnswers = () => {
    setUserAnswers(userAnswers.concat(selectedAnswer.answerType));
    history.push(`/game/result/${props.id}`);
  }

  return(
    <button className={props.className} onClick={() => reRouteAndSetUserAnswers()}>{props.text}</button>
  )
}

export default SayThisButton;