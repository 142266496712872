import React from 'react';

const OtherOptionButton = (props) => {
  const defaultAnswerOptions = props.defaultAnswerOptions;
  const alternateAnswerOptions = props.alternateAnswerOptions;
  const thirdAnswerOptions = props.thirdAnswerOptions;
  const userAnswers = props.userAnswers;
  const last = userAnswers[userAnswers.length - 1];
  const selectedAnswer = props.selectedAnswer;
  

  let buttonText;
  if (selectedAnswer.answerLetter === 'A') {
    buttonText = props.otherOption;
  }else {
    buttonText = props.firstOption;
  }

  const toggleSelectedAnswer = (options) => {
    const answerA = options.find(a => a.answerLetter === 'A');
    const answerB = options.find(a => a.answerLetter === 'B');
    props.selectedAnswer === answerA ? props.setSelectedAnswer(answerB) : props.setSelectedAnswer(answerA);
  }

  if (last === 'default') {
    return (
      <button className={props.className} onClick={() => toggleSelectedAnswer(defaultAnswerOptions, () => props.setSelectedAnswer())}>{buttonText}</button>
    )
  } else if (last === 'alternate') {
    return (
      <button className={props.className}  onClick={() => toggleSelectedAnswer(alternateAnswerOptions, () => props.setSelectedAnswer())}>{buttonText}</button>
    )
  } else if (last === 'third') {
    return (
      <button className={props.className}  onClick={() => toggleSelectedAnswer(thirdAnswerOptions, () => props.setSelectedAnswer())}>{buttonText}</button>
    )
  } else {
    return (
      <>
        <button className={props.className}  onClick={() => toggleSelectedAnswer(defaultAnswerOptions, () => props.setSelectedAnswer())}>{buttonText}</button>
      </>
    )

  }
}

export default OtherOptionButton;