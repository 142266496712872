import { React, useContext } from 'react';
import { UserAnswersContext } from '../../UserAnswersContext';
import {Link} from 'react-router-dom';
import classes from '../../components/Result/Result.module.scss';
import LevelUpText from './LevelUpText';


const LevelUp = (props) => {
  const situationId = props.id;
  const image = props.levelUpImage;

  //sets selectedAnswer to next situation answer so that it displays correctly on the game page
  let setNextAnswer;
  const [userAnswers] = useContext(UserAnswersContext);
  const last = userAnswers[userAnswers.length - 1];

  if (props.gamesLength !== situationId && last === 'default') {
    const nextOptions = props.data.situations.find(a => a.id === props.id + 1);
    const defaultDisplay = nextOptions.defaultAnswerOptions.find(a => a.answerLetter === 'A');
    setNextAnswer = () => {
      props.setSelectedAnswer(defaultDisplay);
    }
  } else if (props.gamesLength !== situationId && last === 'alternate') {
    const nextOptions = props.data.situations.find(a => a.id === props.id + 1);
    const alternateDisplay = nextOptions.alternateAnswerOptions.find(a => a.answerLetter === 'A');
    setNextAnswer = () => {
      props.setSelectedAnswer(alternateDisplay);
    }
  } else if (props.gamesLength !== situationId && last === 'third') {
    const nextOptions = props.data.situations.find(a => a.id === props.id + 1);
    const thirdDisplay = nextOptions.thirdAnswerOptions.find(a => a.answerLetter === 'A');
    setNextAnswer = () => {
      props.setSelectedAnswer(thirdDisplay);
    }
  } else {
    setNextAnswer = () => {
      props.setSelectedAnswer(props.selectedAnswer);
    }
  }

  return(
    <div className={classes.PageContent}>
      <div className={classes.WrapContent}>
        <h1 className={classes.Title}>{props.levelUpTitle.toUpperCase()}</h1>
        <img className={classes.ImageLevelUp} src={image} alt="level up" />
        <LevelUpText text={props.levelUpText} text2={props.levelUpText2} />
      </div>
      <div className={classes.UIWrapper}>
        <div className={classes.Buttons}>
          <div className={classes.ButtonSpacerResult}>
            <Link to={`/game/${situationId + 1}`} style={{ "text-decoration": "none" }}>
              <button onClick={() => setNextAnswer()}>WEITERSPIELEN</button>
            </Link>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default LevelUp;