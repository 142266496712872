import axios from "axios";
import { useState, useEffect, useContext } from 'react';
import { SelectedLanguageContext } from '../../SelectedLanguageContext';

const FetchGameData = () => {
  const [isDeutsch] = useContext(SelectedLanguageContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({situations: []});


  useEffect(() => {
    const fetchData = async () => {
      let result;
      isDeutsch ? 
      result = await axios(
        '/game-data-deutsch.json'
      ) :
      result = await axios(
        '/game-data.json'
      )
      if (result.data?.situations != null) {
        setData(result.data);
        setLoading(false);
      }
    };
    fetchData();
  }, [isDeutsch]);

  return(
    [loading, data]
  )
}

export default FetchGameData;