import React, { useContext, useEffect } from "react";
import { UserAnswersContext } from "../../UserAnswersContext";
import { CurrentSituationContext } from "../../CurrentSituationContext";
import SituationQuestion from "../SituationQuestion/SituationQuestion";
import TestBubble from "../SpeechBubble/TestBubble";
import OtherOptionButton from "../OtherOptionButton/OtherOptionButton";
import SayThisButton from "../SayThisButton/SayThisButton";
import classes from "./Situation.module.scss";

const Situation = (props) => {
  const [userAnswers, setUserAnswers] = useContext(UserAnswersContext);
  const answerText = props.selectedAnswer.answerText;
  // eslint-disable-next-line no-unused-vars
  const [_, setCurrentSituation] = useContext(CurrentSituationContext);

  useEffect(() => {
    setCurrentSituation(props.id);
  }, [props.id, setCurrentSituation]);

  return (
    <div className={classes.PageContent}>
      <div className={classes.WrapContent}>
        <h1 className={classes.Title}>SITUATION #{props.id}</h1>
        {/* <img className={classes.Image} src={`/situationImages/situation${props.id}.svg`} alt="alt" /> */}
        <div>
          <SituationQuestion
            userAnswers={userAnswers}
            questionOptions={props.questionOptions}
          />
        </div>
        <div className={classes.bubbleContainer}>
          <div className={classes.Spacer}>
            <p className={classes.AnswerLetter}>
              {props.selectedAnswer.answerLetter}
            </p>
          </div>
          <div className={classes.BubbleWrapper}>
            <TestBubble
              selectedAnswer={props.selectedAnswer}
              loading={props.loading}
              answerText={answerText}
            />
          </div>
        </div>
      </div>
      <div className={classes.UIWrapper}>
        <div className={classes.Buttons}>
          <div className={classes.ButtonSpacer}>
            <SayThisButton
              className={classes.FirstButton}
              setUserAnswers={setUserAnswers}
              selectedAnswer={props.selectedAnswer}
              id={props.id}
              text="SAG DIES"
            />
            <OtherOptionButton
              otherOption="ANDERE OPTION"
              firstOption="ERSTE OPTION"
              className={classes.SecondButton}
              userAnswers={userAnswers}
              setSelectedAnswer={props.setSelectedAnswer}
              selectedAnswer={props.selectedAnswer}
              defaultAnswerOptions={props.defaultAnswerOptions}
              alternateAnswerOptions={props.alternateAnswerOptions}
              thirdAnswerOptions={props.thirdAnswerOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Situation;
