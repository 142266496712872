import React, {useContext} from 'react';
import { SelectedLanguageContext } from '../../SelectedLanguageContext';
import { Redirect } from "react-router-dom";

const Play = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setIsDeutsch] = useContext(SelectedLanguageContext);

  setIsDeutsch(props.deutsch);

  return(
    <Redirect to={"/game/1"} />
  )
}

export default Play;