import axios from "axios";
import { useState, useEffect, useContext } from 'react';
import { SelectedLanguageContext } from '../../SelectedLanguageContext';

const FetchButtonData = () => {
  const [isDeutsch] = useContext(SelectedLanguageContext);
  const [buttonLoading, setButtonLoading] = useState(true);
  const [buttonData, setButtonData] = useState({ buttons: {} });


  useEffect(() => {
    const fetchData = async () => {
      let result;
      isDeutsch ?
        result = await axios(
          '/button-data-de.json'
        ) :
        result = await axios(
          '/button-data-en.json'
        )
      if (result.data?.buttons != null) {
        setButtonData(result.data.buttons);
        setButtonLoading(false);
      }
    };
    fetchData();
  }, [isDeutsch]);

  return (
    [buttonLoading, buttonData]
  )
}

export default FetchButtonData;