import React, { useContext } from "react";
import classes from "./Header.module.scss";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import { GameEndContext } from "../../GameEndContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Header = ({ currentSituation, gamesLength, isDeutsch }) => {
  const [gameEnd] = useContext(GameEndContext);
  const total = currentSituation / gamesLength;
  const grandTotal = total * 100;

  const closeConfirm = () => {
    if (
      window.confirm(
        isDeutsch
          ? "BIST DU SICHER? Wenn du das Spiel jetzt schließt, wird dein Fortschritt nicht gespeichert. Du kannst das Spiel dann wieder von vorne beginnen."
          : "BIST DU SICHER? Wenn du das Spiel jetzt schließt, wird dein Fortschritt nicht gespeichert. Du kannst das Spiel dann wieder von vorne beginnen."
      )
    ) {
      window.location.replace(
        isDeutsch
          ? "https://talktome.games/"
          : "https://talktome.games/"
      );
      console.log("beep");
    }
  };

  if (gameEnd === true) {
    return (
      <>
        <div className={classes.Container}>
          <div className={classes.Wrapper}>
            <ProgressCircle progress={100} />
            <FontAwesomeIcon
              onClick={() => closeConfirm()}
              icon={faTimes}
              className={classes.Symbol}
            />
          </div>
        </div>
      </>
    );
  } else if (gamesLength === currentSituation) {
    return (
      <>
        <div className={classes.Container}>
          <div className={classes.Wrapper}>
            <ProgressCircle progress={95} />
            <FontAwesomeIcon
              onClick={() => closeConfirm()}
              icon={faTimes}
              className={classes.Symbol}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={classes.Container}>
          <div className={classes.Wrapper}>
            <ProgressCircle progress={grandTotal} />
            <FontAwesomeIcon
              onClick={() => closeConfirm()}
              icon={faTimes}
              className={classes.Symbol}
            />
          </div>
        </div>
      </>
    );
  }
};

export default Header;
