import {React} from 'react';
import classes from '../../components/Result/Result.module.scss';
import LevelUpText from '../LevelUp/LevelUpText';

const FinalResult = (props) => {
  const selectedAnswer = props.selectedAnswer;
  const isGood = selectedAnswer.isGoodAnswer;
  const buttonData = props.buttonData;

  if (isGood === true){
    return(
      <div className={classes.PageContent}>
        <div className={classes.WrapContent}>
          <h1 className={classes.Title}>{buttonData.congratulations}</h1>
          <img className={classes.Image} src="/k5_image_win.svg" alt="You have won the game!" />
          <LevelUpText text={buttonData.goodFinalResult1} text2={buttonData.goodFinalResult2} />
        </div>
        <div style={{ "margin-top": "2.5rem" }} className={classes.UIWrapper}>
          <div className={classes.Buttons}>
            <div className={classes.ButtonSpacerResult}>
              <a style={{ "text-decoration": "none" }} href="https://talktome.games/">
                <button>ANDERE SPIELE</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return(
      <div className={classes.PageContent}>
        <h1 className={classes.Title}>{buttonData.youWereThatClose}</h1>
        <img className={classes.Image} src="/k5_game_over.svg" alt="You have lost the game! Try again" />
        <LevelUpText text={buttonData.badFinalResult1} spacerHeight={2} text2={buttonData.badFinalResult2} />
        <div style={{"margin-top": "2.5rem"}}className={classes.UIWrapper}>
          <div className={classes.Buttons}>
            <div className={classes.ButtonSpacer}>
              <a href="/" style={{ "text-decoration": "none" }}>
                <button className={classes.FirstButton}>ERNEUT VERSUCHEN</button>
              </a>
              <a style={{ "text-decoration": "none" }} href="https://talktome.games/">
                <button>ANDERE SPIELE</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FinalResult;