import React, { useState} from 'react';
import FetchGameData from '../../components/GameData/GameData';
import FetchButtonData from '../../components/ButtonData/ButtonData';
import Game from './Game';
import {UserAnswersContext} from '../../UserAnswersContext';

const GameUI = () => {
  const [loading, data] = FetchGameData();
  const [buttonLoading, buttonData] = FetchButtonData();
  const [userAnswers, setUserAnswers] = useState([{}]);

  return(
    <>
    <UserAnswersContext.Provider value={[userAnswers, setUserAnswers]}>
      <Game loading={loading} data={data} buttonData={buttonData} buttonLoading={buttonLoading}/>
    </UserAnswersContext.Provider>
    </>
  )
}

export default GameUI;