import React from 'react';
import classes from './SituationQuestion.module.scss';


const SituationQuestion = ({userAnswers, questionOptions}) => {
  const defaultQuestion = questionOptions.defaultQuestion;
  const alternateQuestion = questionOptions.alternateQuestion;
  const thirdQuestion = questionOptions.thirdQuestion;
  const last = userAnswers[userAnswers.length - 1];

   if (last === 'default') {
    return (
      <p className={classes.SituationQuestion}>{defaultQuestion}</p>
    )
  } else if (last === 'alternate') {
    return (
      <p className={classes.SituationQuestion}>{alternateQuestion}</p>
    )
  } else if (last === 'third') {
    return (
      <p className={classes.SituationQuestion}>{thirdQuestion}</p>
    )
  } else {
    return (
      <>
        <p className={classes.SituationQuestion}>{defaultQuestion}</p>
      </>
    )

  }
}

export default SituationQuestion;