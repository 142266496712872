import React from 'react';
import classes from '../../components/AnswerResultText/AnswerResultText.module.scss';

const LevelUpText = (props) => {
  return (
    <>
      <div className={classes.TextWrapper}>
        <p className={props.className}>{props.text}</p>
        <div className={classes.Spacer}></div>
        <p className={props.className}>{props.text2}</p>
      </div>
    </>
  )
}

export default LevelUpText;