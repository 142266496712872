import React from "react";
import classes from "./TestBubble.module.scss";
import DOMPurify from "dompurify";

const TestBubble = ({ selectedAnswer, loading, answerText }) => {
  // let bubbleClass = classes.TestBubble;
  // let letterClass = classes.AnswerLetter;

  // if (answerText === undefined) {
  //   bubbleClass = classes.SpeechBubble4;
  // } else if (answerText.length < 80) {
  //   bubbleClass = classes.SpeechBubble1;
  // } else if (answerText.length < 145) {
  //   bubbleClass = classes.SpeechBubble2;
  // } else if (answerText.length < 160) {
  //   bubbleClass = classes.SpeechBubble3;
  // } else if (answerText.length < 200) {
  //   bubbleClass = classes.SpeechBubble4;
  // } else if (answerText.length < 250) {
  //   bubbleClass = classes.SpeechBubble5;
  // }
  // else {
  //   bubbleClass = classes.SpeechBubble5;

  // }
  return (
    <>
      {
        answerText === undefined ? (
          "loading"
        ) : (
          <>
            <div className={classes.bubbleClass}>
              <p
                className={classes.Text}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedAnswer.answerText),
                }}
              ></p>
            </div>
          </>
        )
        //  <div>
        //    <div style={{ "position": "relative" }}>
        //      <div className={letterClass}>
        //        <p>{selectedAnswer.answerLetter}</p>
        //      </div>
        //      <div className={classes.Wrapper}>
        //        <div className={bubbleClass}>
        //          <div className={classes.Container}>
        //            <p>{selectedAnswer.answerText}</p>
        //          </div>
        //        </div>
        //      </div>
        //    </div>
        //  </div>
      }
    </>
  );
};

export default TestBubble;
