import { React } from 'react';
import classes from '../../components/Result/Result.module.scss';
import LevelUpText from '../LevelUp/LevelUpText';

const GameOver = (props) => {
  const paragraphOne = props.selectedAnswer.gameOverText1;
  const paragraphTwo = props.selectedAnswer.gameOverText2;
  const buttonData = props.buttonData;
  return(
    <div className={classes.PageContent}>
    <div className={classes.WrapContent}>
        <h1 className={classes.Title}>GAME OVER</h1>
        <img className={classes.Image} src="/k5_game_over.svg" alt="Game Over" />
        <LevelUpText className={classes.AnswerResultText} spacerHeight={0} text={paragraphOne} text2={paragraphTwo} />
    </div>
    <div className={classes.UIWrapper}>
        <div className={classes.ButtonsGameOver}>
          <div className={classes.ButtonSpacerGameOver}>
            <a href={buttonData.startOverLink} style={{"text-decoration": "none"}}>
              <button className={classes.FirstButton}>NOCH MAL!</button>
            </a>
            <a href="https://talktome.games/" style={{ "text-decoration": "none" }}>
              <button className={classes.SecondButton}>ANDERE SPIELE</button>
            </a>
          </div>
        </div>
    </div>
  </div>
  )
}

export default GameOver;